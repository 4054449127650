import React from 'react'
import GenericTablePage from '@templates/Generic/Table'
import { GridColumns } from '@mui/x-data-grid-pro'

import { getWhitelist, Host, TWhitelistResponse } from '@utils/api'
import useApi from '@hooks/useApi'
import { navigate } from 'gatsby'
import { chipRenderer, tooltipRenderer } from '@utils/tables'

export const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'Id',
    width: 120,
    hide: true,
    type: 'enhancedString'
  },
  {
    field: 'hostname',
    headerName: 'Target Address',
    width: 150,
    type: 'enhancedString'
  },
  {
    field: 'os_info_node',
    headerName: 'Hostname',
    width: 150,
    type: 'enhancedString',
    renderCell: tooltipRenderer
  },
  {
    field: 'active',
    headerName: 'Active',
    type: 'boolean',
    width: 100
  },
  {
    field: 'whitelist_count',
    headerName: 'Count',
    type: 'number',
    width: 100
  },
  {
    field: 'sandfly_whitelist',
    headerName: 'Sandflies',
    flex: 1,
    renderCell: chipRenderer,
    sortable: false,
    type: 'enhancedString'
  }
]

type Whitelist = Host & {
  whitelist_count: number
}

const getWhitelistRows = (hosts: Host[] = []) =>
  !hosts
    ? []
    : hosts.map(
        (host) =>
          ({
            ...host,
            whitelist_count: host.sandfly_whitelist.length
          } as Whitelist)
      )

const WhitelistsPage = () => {
  const api = useApi<TWhitelistResponse>({ apiMethod: getWhitelist })
  const data = api?.response?.data || []
  const rows = getWhitelistRows(data)

  return (
    <GenericTablePage
      title="Whitelist"
      breadcrumbs={[
        {
          title: 'Whitelist'
        }
      ]}
      api={api}
      gridOptions={{
        columns,
        checkboxSelection: false,
        onRowClick: (param: { id: string }) => {
          navigate(`/hosts/host/${param.id}`, { state: { tab: 'Whitelist' } })
        },
        getRowClassName: ({
          getValue,
          id
        }: {
          getValue: (id: string, field: string) => void
          id: string
        }) => `DataGrid__row--active-${getValue(id, 'active')}`,
        rows
      }}
    />
  )
}

export default WhitelistsPage
